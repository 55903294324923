.UsersPage .card {
  text-align: left;
  font-size: 14px;
}

.UsersPage .card .field {
  padding: 2px 7px;
  margin: 0;
  width: auto;
}

.UsersPage .card .name {
  font-size: 18px;
  font-weight: 600;
  display: block;
}

.UsersPage .card .groups {
  display: block;
  min-height: 21px;
  color: #aaa;
}

.UsersPage .card .groups span {
  display: inline-block;
  padding: 1px 7px 2px;
  margin: 0 7px 5px 0;
  border-radius: 10px;
  background: #e67e22;
  color: #fff;
  font-size: 12px;
}

.UsersPage .card .groups span.language {
  background: #9b59b6;
}

.UsersPage .card .icon {
  width: 50px;
  fill: #777;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.UsersPage .card .icon ~ div {
  opacity: 0;
}
