.ReportsPage .Report.cards {
  max-width: none;
}

.ReportsPage .reportTitle,
.ReportsPage .ReportButtons,
.ReportsPage .noData {
  max-width: 940px;
  text-align: center;
  margin: 0 auto 20px;
  font-size: 18px;
}

.ReportsPage .ReportButtons .card {
  display: inline-block !important;
  width: auto;
  background: #eee;
  padding: 7px 15px;
  font-size: inherit !important;
  color: inherit;
  text-decoration: none;
  font-size: 14px !important;
  margin: 0 7px 0;
}

.ReportsPage .noData {
  font-size: inherit;
  margin-top: 30px;
}

.ReportsPage .reportTableWrapper {
  max-width: calc(100% + 20px);
  overflow-x: auto;
  margin: 0 -10px;
  padding: 0 10px;
  -webkit-overflow-scrolling: touch;
  text-align: center;
}

.ReportsPage .Report table {
  margin: auto;
  border-collapse: collapse;
  display: inline-block;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.01);
}

.ReportsPage .Report th,
.ReportsPage .Report td {
  white-space: nowrap;
  font-weight: inherit;
  padding: 7px 20px;
  height: 18px;
  max-width: 200px;
  text-align: center;
  border: 1px solid #eee;
  background: #fff;
  font-size: 13px;
  position: relative;
  outline: none;
  overflow: hidden;
}

.ReportsPage .Report th,
.ReportsPage .Report tfoot td {
  font-weight: 600;
}

.ReportsPage .Report thead th {
  background: #F7F7F7 !important;
  border-color: #ccc;
}

.ReportsPage .Report .dimension,
.ReportsPage .Report .total,
.ReportsPage .Report tfoot td {
  background: #FBFBFB;
}

.ReportsPage .Report .dimension {
  text-align: left;
}

/* Groups */

.ReportsPage .Report .groups td {
  border-top-width: 0;
  border-bottom-color: #ccc;
}

.ReportsPage .Report .groups .lastGroup,
.ReportsPage .Report tr:last-of-type td,
.ReportsPage .Report .groups .group {
  border-bottom-color: #eee;
}

/*** Report Links ***/

.ReportsPage .card {
  cursor: pointer;
  text-align: center;
  font-size: 18px;
}

.ReportsPage .reportName {
  padding: 10px;
}
