.cards {
  margin: 0 auto;
  padding: 30px 10px 0;
  max-width: 940px;  /* 960px - padding*2 */
  text-align: left;
  position: relative;
  min-height: calc(100% - 30px);  /* 100% - top & bottom padding */
}

@media only screen and (min-width: 768px){
  .cards {
    padding-top: 50px;
    min-height: calc(100% - 50px);  /* 100% - top & bottom padding */
  }
}

.card {
  display: inline-block;
  position: relative;
  vertical-align: top;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  margin: 0 10px 20px;
  padding: 15px;
  width: calc(100% - 52px);  /* 100% - padding*2 - margin*2 - border*2 */
}

/* Center Single Card */
.card:only-of-type {
  display: block;
  margin: auto;
}

/* Has Menu */
.card.hasMenu {
  padding: 15px 30px 15px 15px;  /* Extra space on right for menu indicator */
  width: calc(100% - 67px);
}

.hasMenu:before {
  content: "\22EE";
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #999;
  line-height: 1;
  padding: 0;
  transition: color .25s;
}

/*** Card Fields ***/

.card .field {
  text-align: left;
  vertical-align: top;
  display: inline-block;
  margin: 0 1px 2px;
  width: calc(100% - 2px);  /* 100% - margin*2 */
}

/* Status */
.card .field.status span {
  background: #777;
  border-radius: 10px;
  color: #fff;
  padding: 1px 7px 2px;
}

/*** Columns ***/

.Column {
  display: inline-block;
  margin: 0 0 20px;
  padding: 0 10px 0 11px;
  position: relative;
  vertical-align: top;
  width: calc(100% - 21px);
}

.Column + .Column.divider {
  padding-left: 10px;
  border-left: 1px solid rgba(0,0,0,.1);
}

/*** Buckets ***/

.Bucket h1 {
  font-size: 18px;
  text-align: center;
  padding: 0;
  margin: 0 0 15px;
  position: relative;
  font-weight: 600;
  line-height: 1;
}

/* Cards */
.Bucket .headers,
.Bucket .card {
  margin: 0;
  padding: 8px 12px 8px 8px;
  width: calc(100% - 24px);
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
  border-radius: 0;
  box-shadow: none;
}

.Bucket .headers {
  padding-top: 0;
  padding-bottom: 5px;
  border-color: #000;
  font-weight: 600;
}

.Bucket .hasMenu:before {
  top: 8px;
  right: 6px;
  font-size: 16px;
}

.Bucket .items .card:last-child {
  border-bottom: 0;
}

/* Fields */
.Bucket .field {
  position: relative;
  font-size: inherit !important;
  overflow: hidden;
  white-space: nowrap;
}

.Bucket .field:before {
  content: "";
  background-image: linear-gradient(to right, rgba(255,255,255,0), white);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
}

.Bucket .field.status span {
  background: none !important;
  padding: 0 !important;
  color: inherit !important;
}

/*** Responsive Widths ***/

/* Hidden */
.card.grid-0,
.card .grid-0 { display: none; }
/* Card */
.card.grid-1 { width: calc(8.33% - 52px); }
.card.grid-2 { width: calc(16.66% - 52px); }
.card.grid-3 { width: calc(25.00% - 52px); }
.card.grid-4 { width: calc(33.33% - 52px); }
.card.grid-5 { width: calc(41.66% - 52px); }
.card.grid-6 { width: calc(50.00% - 52px); }
.card.grid-7 { width: calc(58.33% - 52px); }
.card.grid-8 { width: calc(66.66% - 52px); }
.card.grid-9 { width: calc(75.00% - 52px); }
.card.grid-10 { width: calc(83.33% - 52px); }
.card.grid-11 { width: calc(91.66% - 52px); }
.card.grid-12 { width: calc(100.00% - 52px); }
/* Has Menu */
.card.hasMenu.grid-1 { width: calc(8.33% - 67px); }
.card.hasMenu.grid-2 { width: calc(16.66% - 67px); }
.card.hasMenu.grid-3 { width: calc(25.00% - 67px); }
.card.hasMenu.grid-4 { width: calc(33.33% - 67px); }
.card.hasMenu.grid-5 { width: calc(41.66% - 67px); }
.card.hasMenu.grid-6 { width: calc(50.00% - 67px); }
.card.hasMenu.grid-7 { width: calc(58.33% - 67px); }
.card.hasMenu.grid-8 { width: calc(66.66% - 67px); }
.card.hasMenu.grid-9 { width: calc(75.00% - 67px); }
.card.hasMenu.grid-10 { width: calc(83.33% - 67px); }
.card.hasMenu.grid-11 { width: calc(91.66% - 67px); }
.card.hasMenu.grid-12 { width: calc(100.00% - 67px); }
/* Field */
.card .field.grid-1 { width: calc(8.33% - 2px); }
.card .field.grid-2 { width: calc(16.66% - 2px); }
.card .field.grid-3 { width: calc(25.00% - 2px); }
.card .field.grid-4 { width: calc(33.33% - 2px); }
.card .field.grid-5 { width: calc(41.66% - 2px); }
.card .field.grid-6 { width: calc(50.00% - 2px); }
.card .field.grid-7 { width: calc(58.33% - 2px); }
.card .field.grid-8 { width: calc(66.66% - 2px); }
.card .field.grid-9 { width: calc(75.00% - 2px); }
.card .field.grid-10 { width: calc(83.33% - 2px); }
.card .field.grid-11 { width: calc(91.66% - 2px); }
.card .field.grid-12 { width: calc(100.00% - 2px); }
/* Column */
.Column.grid-1 { width: calc(8.33% - 21px); }
.Column.grid-2 { width: calc(16.66% - 21px); }
.Column.grid-3 { width: calc(25.00% - 21px); }
.Column.grid-4 { width: calc(33.33% - 21px); }
.Column.grid-5 { width: calc(41.66% - 21px); }
.Column.grid-6 { width: calc(50.00% - 21px); }
.Column.grid-7 { width: calc(58.33% - 21px); }
.Column.grid-8 { width: calc(66.66% - 21px); }
.Column.grid-9 { width: calc(75.00% - 21px); }
.Column.grid-10 { width: calc(83.33% - 21px); }
.Column.grid-11 { width: calc(91.66% - 21px); }
.Column.grid-12 { width: calc(100.00% - 21px); }
.Column.grid-12.divider {
  padding-left: 11px;
  border-left: 0;
}

/* Extra Large */
@media only screen and (min-width: 1201px){
  /* Hidden */
  .card.grid-xl-0,
  .card .grid-xl-0 { display: none; }
  /* Card */
  .card.grid-xl-1 { width: calc(8.33% - 52px); }
  .card.grid-xl-2 { width: calc(16.66% - 52px); }
  .card.grid-xl-3 { width: calc(25.00% - 52px); }
  .card.grid-xl-4 { width: calc(33.33% - 52px); }
  .card.grid-xl-5 { width: calc(41.66% - 52px); }
  .card.grid-xl-6 { width: calc(50.00% - 52px); }
  .card.grid-xl-7 { width: calc(58.33% - 52px); }
  .card.grid-xl-8 { width: calc(66.66% - 52px); }
  .card.grid-xl-9 { width: calc(75.00% - 52px); }
  .card.grid-xl-10 { width: calc(83.33% - 52px); }
  .card.grid-xl-11 { width: calc(91.66% - 52px); }
  .card.grid-xl-12 { width: calc(100.00% - 52px); }
  /* Has Menu */
  .card.hasMenu.grid-xl-1 { width: calc(8.33% - 67px); }
  .card.hasMenu.grid-xl-2 { width: calc(16.66% - 67px); }
  .card.hasMenu.grid-xl-3 { width: calc(25.00% - 67px); }
  .card.hasMenu.grid-xl-4 { width: calc(33.33% - 67px); }
  .card.hasMenu.grid-xl-5 { width: calc(41.66% - 67px); }
  .card.hasMenu.grid-xl-6 { width: calc(50.00% - 67px); }
  .card.hasMenu.grid-xl-7 { width: calc(58.33% - 67px); }
  .card.hasMenu.grid-xl-8 { width: calc(66.66% - 67px); }
  .card.hasMenu.grid-xl-9 { width: calc(75.00% - 67px); }
  .card.hasMenu.grid-xl-10 { width: calc(83.33% - 67px); }
  .card.hasMenu.grid-xl-11 { width: calc(91.66% - 67px); }
  .card.hasMenu.grid-xl-12 { width: calc(100.00% - 67px); }
  /* Field */
  .card .field.grid-xl-1 { width: calc(8.33% - 2px); }
  .card .field.grid-xl-2 { width: calc(16.66% - 2px); }
  .card .field.grid-xl-3 { width: calc(25.00% - 2px); }
  .card .field.grid-xl-4 { width: calc(33.33% - 2px); }
  .card .field.grid-xl-5 { width: calc(41.66% - 2px); }
  .card .field.grid-xl-6 { width: calc(50.00% - 2px); }
  .card .field.grid-xl-7 { width: calc(58.33% - 2px); }
  .card .field.grid-xl-8 { width: calc(66.66% - 2px); }
  .card .field.grid-xl-9 { width: calc(75.00% - 2px); }
  .card .field.grid-xl-10 { width: calc(83.33% - 2px); }
  .card .field.grid-xl-11 { width: calc(91.66% - 2px); }
  .card .field.grid-xl-12 { width: calc(100.00% - 2px); }
  /* Column */
  .Column.grid-xl-1 { width: calc(8.33% - 21px); }
  .Column.grid-xl-2 { width: calc(16.66% - 21px); }
  .Column.grid-xl-3 { width: calc(25.00% - 21px); }
  .Column.grid-xl-4 { width: calc(33.33% - 21px); }
  .Column.grid-xl-5 { width: calc(41.66% - 21px); }
  .Column.grid-xl-6 { width: calc(50.00% - 21px); }
  .Column.grid-xl-7 { width: calc(58.33% - 21px); }
  .Column.grid-xl-8 { width: calc(66.66% - 21px); }
  .Column.grid-xl-9 { width: calc(75.00% - 21px); }
  .Column.grid-xl-10 { width: calc(83.33% - 21px); }
  .Column.grid-xl-11 { width: calc(91.66% - 21px); }
  .Column.grid-xl-12 { width: calc(100.00% - 21px); }
  .Column.grid-xl-12.divider {
    padding-left: 11px;
    border-left: 0;
  }
}

/* Large Screen */
@media only screen and (max-width: 1200px){
  /* Hidden */
  .card.grid-lg-0,
  .card .grid-lg-0 { display: none; }
  /* Card */
  .card.grid-lg-1 { width: calc(8.33% - 52px); }
  .card.grid-lg-2 { width: calc(16.66% - 52px); }
  .card.grid-lg-3 { width: calc(25.00% - 52px); }
  .card.grid-lg-4 { width: calc(33.33% - 52px); }
  .card.grid-lg-5 { width: calc(41.66% - 52px); }
  .card.grid-lg-6 { width: calc(50.00% - 52px); }
  .card.grid-lg-7 { width: calc(58.33% - 52px); }
  .card.grid-lg-8 { width: calc(66.66% - 52px); }
  .card.grid-lg-9 { width: calc(75.00% - 52px); }
  .card.grid-lg-10 { width: calc(83.33% - 52px); }
  .card.grid-lg-11 { width: calc(91.66% - 52px); }
  .card.grid-lg-12 { width: calc(100.00% - 52px); }
  /* Has Menu */
  .card.hasMenu.grid-lg-1 { width: calc(8.33% - 67px); }
  .card.hasMenu.grid-lg-2 { width: calc(16.66% - 67px); }
  .card.hasMenu.grid-lg-3 { width: calc(25.00% - 67px); }
  .card.hasMenu.grid-lg-4 { width: calc(33.33% - 67px); }
  .card.hasMenu.grid-lg-5 { width: calc(41.66% - 67px); }
  .card.hasMenu.grid-lg-6 { width: calc(50.00% - 67px); }
  .card.hasMenu.grid-lg-7 { width: calc(58.33% - 67px); }
  .card.hasMenu.grid-lg-8 { width: calc(66.66% - 67px); }
  .card.hasMenu.grid-lg-9 { width: calc(75.00% - 67px); }
  .card.hasMenu.grid-lg-10 { width: calc(83.33% - 67px); }
  .card.hasMenu.grid-lg-11 { width: calc(91.66% - 67px); }
  .card.hasMenu.grid-lg-12 { width: calc(100.00% - 67px); }
  /* Field */
  .card .field.grid-lg-1 { width: calc(8.33% - 2px); }
  .card .field.grid-lg-2 { width: calc(16.66% - 2px); }
  .card .field.grid-lg-3 { width: calc(25.00% - 2px); }
  .card .field.grid-lg-4 { width: calc(33.33% - 2px); }
  .card .field.grid-lg-5 { width: calc(41.66% - 2px); }
  .card .field.grid-lg-6 { width: calc(50.00% - 2px); }
  .card .field.grid-lg-7 { width: calc(58.33% - 2px); }
  .card .field.grid-lg-8 { width: calc(66.66% - 2px); }
  .card .field.grid-lg-9 { width: calc(75.00% - 2px); }
  .card .field.grid-lg-10 { width: calc(83.33% - 2px); }
  .card .field.grid-lg-11 { width: calc(91.66% - 2px); }
  .card .field.grid-lg-12 { width: calc(100.00% - 2px); }
  /* Column */
  .Column.grid-lg-1 { width: calc(8.33% - 21px); }
  .Column.grid-lg-2 { width: calc(16.66% - 21px); }
  .Column.grid-lg-3 { width: calc(25.00% - 21px); }
  .Column.grid-lg-4 { width: calc(33.33% - 21px); }
  .Column.grid-lg-5 { width: calc(41.66% - 21px); }
  .Column.grid-lg-6 { width: calc(50.00% - 21px); }
  .Column.grid-lg-7 { width: calc(58.33% - 21px); }
  .Column.grid-lg-8 { width: calc(66.66% - 21px); }
  .Column.grid-lg-9 { width: calc(75.00% - 21px); }
  .Column.grid-lg-10 { width: calc(83.33% - 21px); }
  .Column.grid-lg-11 { width: calc(91.66% - 21px); }
  .Column.grid-lg-12 { width: calc(100.00% - 21px); }
  .Column.grid-lg-12.divider {
    padding-left: 11px;
    border-left: 0;
  }
}

/* Medium */
@media only screen and (max-width: 992px){
  /* Hidden */
  .card.grid-md-0,
  .card .grid-md-0 { display: none; }
  /* Card */
  .card.grid-md-1 { width: calc(8.33% - 52px); }
  .card.grid-md-2 { width: calc(16.66% - 52px); }
  .card.grid-md-3 { width: calc(25.00% - 52px); }
  .card.grid-md-4 { width: calc(33.33% - 52px); }
  .card.grid-md-5 { width: calc(41.66% - 52px); }
  .card.grid-md-6 { width: calc(50.00% - 52px); }
  .card.grid-md-7 { width: calc(58.33% - 52px); }
  .card.grid-md-8 { width: calc(66.66% - 52px); }
  .card.grid-md-9 { width: calc(75.00% - 52px); }
  .card.grid-md-10 { width: calc(83.33% - 52px); }
  .card.grid-md-11 { width: calc(91.66% - 52px); }
  .card.grid-md-12 { width: calc(100.00% - 52px); }
  /* Has Menu */
  .card.hasMenu.grid-md-1 { width: calc(8.33% - 67px); }
  .card.hasMenu.grid-md-2 { width: calc(16.66% - 67px); }
  .card.hasMenu.grid-md-3 { width: calc(25.00% - 67px); }
  .card.hasMenu.grid-md-4 { width: calc(33.33% - 67px); }
  .card.hasMenu.grid-md-5 { width: calc(41.66% - 67px); }
  .card.hasMenu.grid-md-6 { width: calc(50.00% - 67px); }
  .card.hasMenu.grid-md-7 { width: calc(58.33% - 67px); }
  .card.hasMenu.grid-md-8 { width: calc(66.66% - 67px); }
  .card.hasMenu.grid-md-9 { width: calc(75.00% - 67px); }
  .card.hasMenu.grid-md-10 { width: calc(83.33% - 67px); }
  .card.hasMenu.grid-md-11 { width: calc(91.66% - 67px); }
  .card.hasMenu.grid-md-12 { width: calc(100.00% - 67px); }
  /* Field */
  .card .field.grid-md-1 { width: calc(8.33% - 2px); }
  .card .field.grid-md-2 { width: calc(16.66% - 2px); }
  .card .field.grid-md-3 { width: calc(25.00% - 2px); }
  .card .field.grid-md-4 { width: calc(33.33% - 2px); }
  .card .field.grid-md-5 { width: calc(41.66% - 2px); }
  .card .field.grid-md-6 { width: calc(50.00% - 2px); }
  .card .field.grid-md-7 { width: calc(58.33% - 2px); }
  .card .field.grid-md-8 { width: calc(66.66% - 2px); }
  .card .field.grid-md-9 { width: calc(75.00% - 2px); }
  .card .field.grid-md-10 { width: calc(83.33% - 2px); }
  .card .field.grid-md-11 { width: calc(91.66% - 2px); }
  .card .field.grid-md-12 { width: calc(100.00% - 2px); }
  /* Column */
  .Column.grid-md-1 { width: calc(8.33% - 21px); }
  .Column.grid-md-2 { width: calc(16.66% - 21px); }
  .Column.grid-md-3 { width: calc(25.00% - 21px); }
  .Column.grid-md-4 { width: calc(33.33% - 21px); }
  .Column.grid-md-5 { width: calc(41.66% - 21px); }
  .Column.grid-md-6 { width: calc(50.00% - 21px); }
  .Column.grid-md-7 { width: calc(58.33% - 21px); }
  .Column.grid-md-8 { width: calc(66.66% - 21px); }
  .Column.grid-md-9 { width: calc(75.00% - 21px); }
  .Column.grid-md-10 { width: calc(83.33% - 21px); }
  .Column.grid-md-11 { width: calc(91.66% - 21px); }
  .Column.grid-md-12 { width: calc(100.00% - 21px); }
  .Column.grid-md-12.divider {
    padding-left: 11px;
    border-left: 0;
  }
}

/* Small */
@media only screen and (max-width: 768px){
  /* Hidden */
  .card.grid-sm-0,
  .card .grid-sm-0 { display: none; }
  /* Card */
  .card.grid-sm-1 { width: calc(8.33% - 52px); }
  .card.grid-sm-2 { width: calc(16.66% - 52px); }
  .card.grid-sm-3 { width: calc(25.00% - 52px); }
  .card.grid-sm-4 { width: calc(33.33% - 52px); }
  .card.grid-sm-5 { width: calc(41.66% - 52px); }
  .card.grid-sm-6 { width: calc(50.00% - 52px); }
  .card.grid-sm-7 { width: calc(58.33% - 52px); }
  .card.grid-sm-8 { width: calc(66.66% - 52px); }
  .card.grid-sm-9 { width: calc(75.00% - 52px); }
  .card.grid-sm-10 { width: calc(83.33% - 52px); }
  .card.grid-sm-11 { width: calc(91.66% - 52px); }
  .card.grid-sm-12 { width: calc(100.00% - 52px); }
  /* Has Menu */
  .card.hasMenu.grid-sm-1 { width: calc(8.33% - 67px); }
  .card.hasMenu.grid-sm-2 { width: calc(16.66% - 67px); }
  .card.hasMenu.grid-sm-3 { width: calc(25.00% - 67px); }
  .card.hasMenu.grid-sm-4 { width: calc(33.33% - 67px); }
  .card.hasMenu.grid-sm-5 { width: calc(41.66% - 67px); }
  .card.hasMenu.grid-sm-6 { width: calc(50.00% - 67px); }
  .card.hasMenu.grid-sm-7 { width: calc(58.33% - 67px); }
  .card.hasMenu.grid-sm-8 { width: calc(66.66% - 67px); }
  .card.hasMenu.grid-sm-9 { width: calc(75.00% - 67px); }
  .card.hasMenu.grid-sm-10 { width: calc(83.33% - 67px); }
  .card.hasMenu.grid-sm-11 { width: calc(91.66% - 67px); }
  .card.hasMenu.grid-sm-12 { width: calc(100.00% - 67px); }
  /* Field */
  .card .field.grid-sm-1 { width: calc(8.33% - 2px); }
  .card .field.grid-sm-2 { width: calc(16.66% - 2px); }
  .card .field.grid-sm-3 { width: calc(25.00% - 2px); }
  .card .field.grid-sm-4 { width: calc(33.33% - 2px); }
  .card .field.grid-sm-5 { width: calc(41.66% - 2px); }
  .card .field.grid-sm-6 { width: calc(50.00% - 2px); }
  .card .field.grid-sm-7 { width: calc(58.33% - 2px); }
  .card .field.grid-sm-8 { width: calc(66.66% - 2px); }
  .card .field.grid-sm-9 { width: calc(75.00% - 2px); }
  .card .field.grid-sm-10 { width: calc(83.33% - 2px); }
  .card .field.grid-sm-11 { width: calc(91.66% - 2px); }
  .card .field.grid-sm-12 { width: calc(100.00% - 2px); }
  /* Column */
  .Column.grid-sm-1 { width: calc(8.33% - 21px); }
  .Column.grid-sm-2 { width: calc(16.66% - 21px); }
  .Column.grid-sm-3 { width: calc(25.00% - 21px); }
  .Column.grid-sm-4 { width: calc(33.33% - 21px); }
  .Column.grid-sm-5 { width: calc(41.66% - 21px); }
  .Column.grid-sm-6 { width: calc(50.00% - 21px); }
  .Column.grid-sm-7 { width: calc(58.33% - 21px); }
  .Column.grid-sm-8 { width: calc(66.66% - 21px); }
  .Column.grid-sm-9 { width: calc(75.00% - 21px); }
  .Column.grid-sm-10 { width: calc(83.33% - 21px); }
  .Column.grid-sm-11 { width: calc(91.66% - 21px); }
  .Column.grid-sm-12 { width: calc(100.00% - 21px); }
  .Column.grid-sm-12.divider {
    padding-left: 11px;
    border-left: 0;
  }
}

/* Extra Small */
@media only screen and (max-width: 576px){
  /* Hidden */
  .card.grid-xs-0,
  .card .grid-xs-0 { display: none; }
  /* Card */
  .card.grid-xs-1 { width: calc(8.33% - 52px); }
  .card.grid-xs-2 { width: calc(16.66% - 52px); }
  .card.grid-xs-3 { width: calc(25.00% - 52px); }
  .card.grid-xs-4 { width: calc(33.33% - 52px); }
  .card.grid-xs-5 { width: calc(41.66% - 52px); }
  .card.grid-xs-6 { width: calc(50.00% - 52px); }
  .card.grid-xs-7 { width: calc(58.33% - 52px); }
  .card.grid-xs-8 { width: calc(66.66% - 52px); }
  .card.grid-xs-9 { width: calc(75.00% - 52px); }
  .card.grid-xs-10 { width: calc(83.33% - 52px); }
  .card.grid-xs-11 { width: calc(91.66% - 52px); }
  .card.grid-xs-12 { width: calc(100.00% - 52px); }
  /* Has Menu */
  .card.hasMenu.grid-xs-1 { width: calc(8.33% - 67px); }
  .card.hasMenu.grid-xs-2 { width: calc(16.66% - 67px); }
  .card.hasMenu.grid-xs-3 { width: calc(25.00% - 67px); }
  .card.hasMenu.grid-xs-4 { width: calc(33.33% - 67px); }
  .card.hasMenu.grid-xs-5 { width: calc(41.66% - 67px); }
  .card.hasMenu.grid-xs-6 { width: calc(50.00% - 67px); }
  .card.hasMenu.grid-xs-7 { width: calc(58.33% - 67px); }
  .card.hasMenu.grid-xs-8 { width: calc(66.66% - 67px); }
  .card.hasMenu.grid-xs-9 { width: calc(75.00% - 67px); }
  .card.hasMenu.grid-xs-10 { width: calc(83.33% - 67px); }
  .card.hasMenu.grid-xs-11 { width: calc(91.66% - 67px); }
  .card.hasMenu.grid-xs-12 { width: calc(100.00% - 67px); }
  /* Field */
  .card .field.grid-xs-1 { width: calc(8.33% - 2px); }
  .card .field.grid-xs-2 { width: calc(16.66% - 2px); }
  .card .field.grid-xs-3 { width: calc(25.00% - 2px); }
  .card .field.grid-xs-4 { width: calc(33.33% - 2px); }
  .card .field.grid-xs-5 { width: calc(41.66% - 2px); }
  .card .field.grid-xs-6 { width: calc(50.00% - 2px); }
  .card .field.grid-xs-7 { width: calc(58.33% - 2px); }
  .card .field.grid-xs-8 { width: calc(66.66% - 2px); }
  .card .field.grid-xs-9 { width: calc(75.00% - 2px); }
  .card .field.grid-xs-10 { width: calc(83.33% - 2px); }
  .card .field.grid-xs-11 { width: calc(91.66% - 2px); }
  .card .field.grid-xs-12 { width: calc(100.00% - 2px); }
  /* Column */
  .Column.grid-xs-1 { width: calc(8.33% - 21px); }
  .Column.grid-xs-2 { width: calc(16.66% - 21px); }
  .Column.grid-xs-3 { width: calc(25.00% - 21px); }
  .Column.grid-xs-4 { width: calc(33.33% - 21px); }
  .Column.grid-xs-5 { width: calc(41.66% - 21px); }
  .Column.grid-xs-6 { width: calc(50.00% - 21px); }
  .Column.grid-xs-7 { width: calc(58.33% - 21px); }
  .Column.grid-xs-8 { width: calc(66.66% - 21px); }
  .Column.grid-xs-9 { width: calc(75.00% - 21px); }
  .Column.grid-xs-10 { width: calc(83.33% - 21px); }
  .Column.grid-xs-11 { width: calc(91.66% - 21px); }
  .Column.grid-xs-12 { width: calc(100.00% - 21px); }
  .Column.grid-xs-12.divider {
    padding-left: 11px;
    border-left: 0;
  }
}
