.login {
  height: 100%;
  background: rgba(63, 154, 247, 1);
  /* background: linear-gradient(0deg, rgba(63, 154, 247, .7), rgba(63, 154, 247, 1)); */
  position: relative;
  color: #fff;
}

.login form {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login h1 {
  font-weight: 400;
  text-align: center;
  padding: 0;
  margin: 0 0 30px;
  font-size: 30px;
}

.login .toggle {
  width: 175px;
  border-radius: 10px;
  border: 1px solid #fff;
  margin: -15px auto 20px;
  text-align: center;
  overflow: hidden;
  font-size: 0;
  background: #fff;
}

.login .toggle * {
  display: inline-block;
  padding: 4px 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  width: 50%;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  background: rgba(63, 154, 247, 1);
}

.login .toggle .active {
  background: #fff;
  color: rgba(63, 154, 247, 1);
  cursor: default;
}

.login label,
.login input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.login label {
  text-transform: uppercase;
  font-size: 12px;
}

.login input::placeholder {
  opacity: 0.5;
}

.login input {
  display: block;
  background: none;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  outline: none;
  padding: 3px;
  width: calc(100% - 6px);
  margin: 0 0 15px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
}

.login input[disabled] {
  cursor: not-allowed;
  opacity: 1;
}

/* Autofill style override */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s,
    color 5000s ease-in-out 0s;
}

.login .message {
  text-align: center;
  margin-bottom: -15px;
}

.login .message a {
  color: inherit;
}

.login label + .message {
  font-size: 14px;
  height: 40px;
  margin-bottom: -40px;
}

.login button {
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  background: rgba(255, 255, 255, 1);
  border: 0;
  outline: none;
  width: 100%;
  color: rgba(63, 154, 247, 1);
  margin: 50px 0 0;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.login button.loading {
  opacity: .5;
}

.login button.error {
  background: rgba(231, 76, 60, 1);
  color: #fff;
}

.login .links {
  margin: 30px 0 0;
  text-align: center;
  width: 100%;
}

.login .links a {
  color: inherit;
  text-decoration: none;
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
}

.login .message + .links a {
  margin: 30px 0 0;
  padding: 4px 15px 5px;
  border-radius: 10px;
  color: rgba(63, 154, 247, 1);
  background: #fff;
  text-decoration: none !important;
}

.login .links *:hover {
  text-decoration: underline;
}
