/*** Page Buttons ***/
.page_buttons {
  position: fixed;
  z-index: 85;
  font-size: 0;
  color: #fff;
  fill: #fff;
  user-select: none;
}

.page_buttons.top {
  /* top: SEE INLINE STYLE */
  padding-top: 3px;
}

.page_buttons.top.right {
  right: 8px;
}

.page_buttons.top.left {
  left: 8px;
}

.page_buttons.top > .button {
  padding: 0 7px;
  background: none !important;
}

.page_buttons.top .icon {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.page_buttons.top .icon ~ span {
  padding-left: 5px;
  vertical-align: middle;
  font-size: 15px;
}

.page_buttons.bottom {
  bottom: 30px;
  text-align: center;
  font-size: 12px;
}

.page_buttons.bottom > .button {
  display: block;
  margin: 10px;
  padding: 7px;
  background: #bbb;
  border-radius: 10px;
  border: 2px solid #fff;
}

.page_buttons.bottom .icon {
  height: 40px;
  width: 40px;
  display: block;
}

.page_buttons.bottom span {
  display: block;
}

.page_buttons.bottom.right {
  right: 15px;
}

.page_buttons.bottom.left {
  left: 15px;
}

.page_buttons > .button {
  display: inline-block;
  cursor: pointer;
}
