/*** Mini Modal ***/

.miniModal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  height: auto;
  background: #fff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.03);
}

.miniModal h1 {
  color: #000;
  background: #f9f9f9;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #eee;
  margin: 0 0 10px;
}

.miniModal table {
  width: 100%;
  margin: 8px 0;
}

.miniModal th,
.miniModal td {
  text-align: right;
  padding: 10px 15px;
  vertical-align: middle;
}

.miniModal th {
  text-align: left;
  font-weight: normal;
  color: #777;
  width: 30px;
  padding-right: 0;
}

.miniModal th + th {
  width: auto;
  padding-right: 0;
  padding-left: 7px;
}

.miniModal .icon {
  width: 30px;
  height: 30px;
  display: block;
  fill: rgba(63, 154, 247, 1);
}

.miniModal .button {
  display: inline-block;
  border: 1px solid rgba(63, 154, 247, 1);
  color: rgba(63, 154, 247, 1);
  border-radius: 5px;
  padding: 5px 5px;
  text-transform: uppercase;
  width: 90px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}

.miniModal .continue,
.miniModal .continue * {
  border-color: #ccc;
  color: #aaa;
  fill: #ccc;
}

/*** Content ***/

.InstallPage .cards {
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}

.InstallPage h1 {
  font-size: 30px;
}

.InstallPage h2 {
  font-size: 20px;
  color: #777;
}

.InstallPage .links {
  margin: 40px 0;
  text-align: center;
}

.InstallPage .download_link {
  display: inline-block;
  margin: 10px 20px;
}

.InstallPage .download_img {
  width: 100%;
  max-width: 200px;
}

.InstallPage img.hero {
  width: 350px;
  max-width: 80%;
}
