* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rootContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

.icon {
  display: inline-block;
}

.icon svg {
  height: 100%;
}

/*** Coverall ***/

.coverall {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
}

.coverall ~ .page_buttons.bottom,
.coverall ~ .cards .widget,
.coverall ~ .bottom,
.coverall ~ .cards > * {
  filter: blur(5px);
  opacity: .5;
}

/*** Page ***/

.page {
  height: 100%;
}

/*** Invalid Browser ***/

.invalid {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  text-align: center;
}

.invalid h1, h2, h3 {
  font-weight: inherit;
  margin: 20px 10px;
  font-size: 40px;
}

.invalid h2, h3 {
  font-size: 20px;
  color: #777;
}

.invalid h3 {
  margin-top: 50px;
  font-size: 16px;
}

.invalid a {
  text-decoration: none;
  color: #3498db;
}

.invalid a:hover {
  text-decoration: underline;
}

/*** Empty State ***/

.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ccc;
  fill: #ccc;
  text-align: center;
  min-width: 300px;
}

.empty .icon {
  height: 100px;
}

.empty h1 {
  margin: 0 0 10px;
  font-size: 24px;
}

.empty h2 {
  margin: 0;
  font-size: 16px;
}
