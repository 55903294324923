#UpdaterCoverall ~ .page_buttons.top {
  display: none;
}

.UpdaterContainer {
  position: fixed;
  left: 40px;
  bottom: 0;
  z-index: 95;
}

.Updater {
  min-width: 200px;
  display: inline-block;
  background: #fff;
  border: 1px solid rgba(0,0,0,.1);
  border-top: 10px solid rgba(63, 154, 247, 1);
  box-shadow: 0 6px 12px 0 rgba(0,0,0,.12);
  border-radius: 10px;
  padding: 15px 25px 20px;
}

.Updater .wrapper {
  max-width: 320px;
  margin: auto;
}

.Updater h1,
.Updater h2,
.Updater h3 {
  margin: 0 0 5px;
  font-size: 15px;
  font-weight: normal;
  color: #777;
}

.Updater h1 {
  color: #000;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 500;
}

.Updater h3 {
  font-size: 12px;
  margin-bottom: 10px;
}

.Updater h3 a {
  text-decoration: underline;
  color: inherit;
}

.Updater .CountDown {
  font-weight: bold;
}

.Updater .wait {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #aaa;
  border-bottom: 1px dashed;
  border-color: #aaa;
  cursor: pointer;
  padding: 4px 0 0;  /* Adjust vertical alignment */
}

.Updater .wait:hover {
  border-bottom: 1px solid;
}

.Updater button {
  outline: none;
  border: 1px solid rgba(63, 154, 247, 1);
  color: rgba(63, 154, 247, 1);
  border-radius: 5px;
  background: none;
  font-size: 14px;
  padding: 5px 12px;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}

.Updater button:hover {
  background: rgba(63, 154, 247, 1);
  color: #fff;
}

.Updater .wait ~ button {
  float: right;
}

.Updater .spacer {
  display: none;
}

.UpdaterContainer .mobileSpacer {
  display: none;
}

/*** Mobile Landscape Style ***/

@media only screen and (max-width: 750px){
  .UpdaterContainer {
    left: 20px;
  }

  .UpdaterContainer .mobileSpacer {
    display: block;
  }

  .UpdaterContainer .desktopSpacer {
    display: none;
  }
}

/*** Mobile Style ***/

@media only screen and (max-width: 576px){
  .UpdaterContainer {
    left: 0;
    right: 0;
    bottom: 0;
  }

  .UpdaterContainer .spacer {
    display: none;
  }

  .Updater {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding-bottom: 0;
    width: calc(100% - 50px);
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  .Updater h1 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .Updater h2 {
    font-size: 16px;
  }

  .Updater .spacer {
    display: block;
  }
}
