/*** Status ***/

.StatusComponent {
  z-index: 45;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.StatusComponent ~ div {
  filter: blur(5px);
}

.StatusComponent ~ footer {
  display: none;
}

.StatusComponent .content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.StatusComponent h1 {
  font-weight: inherit;
  font-size: 18px;
  padding: 0;
  margin: 0 0 10px;
}

.StatusComponent h2 {
  font-weight: inherit;
  font-size: 14px;
  padding: 0;
  margin: 0 0 10px;
}

.StatusComponent h2.clickable {
  cursor: pointer;
  text-decoration: underline;
}

.StatusComponent h2 a {
  color: inherit;
}

.StatusComponent .icon[name=spinner] {
  width: 75px;
  fill : #777;
}
