/*** Loading ***/

.Map.loading,
.Map.error {
  background: #ddd;
  position: relative;
}

.Map .controls .icon,
.Map.loading .icon,
.Map.error .icon {
  width: 75px;
  fill : #777;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Map .message {
  display: block;
  margin: 15px 0 0;
  padding: 0;
  position: absolute;
  top: calc(50% + 75px/2);  /* 50% + 1/2 icon height */
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #777;
  font-weight: 600;
}

/*** Description ***/

.Map .mapDescription {
  background: rgba(255, 255, 255, .8);
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  max-width: 200px;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.Map .mapDescription .icon {
  fill: rgba(255, 69, 58, 1);
  height: 22px;
  padding-right: 5px;
  padding-bottom: 2px;
  vertical-align: middle;
}

/*** Controls ***/

.Map .mapboxgl-control-container {
  display: none;
}

.Map .screenControl {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Map > div {
  height: 100% !important;
  width: 100% !important;
}

.Map .mapboxgl-ctrl button {
  outline: none !important;
  box-shadow: none !important;
}

.Map .navControl {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.Map .styleControl {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.Map .styleControl button {
  background-repeat: no-repeat;
  background-position: center;
}

.Map .styleControl .button-streets {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' height='20' width='20'%3E%3Ctitle%3Ecar-15.svg%3C/title%3E%3Crect fill='none' x='0' y='0'  width='20' height='20'%3E%3C/rect%3E%3Cpath fill='%23aaa' transform='translate(0 0)' d='M14,7c-0.004-0.6904-0.4787-1.2889-1.15-1.45l-1.39-3.24l0,0l0,0l0,0C11.3833,2.1233,11.2019,2.001,11,2H4 C3.8124,2.0034,3.6425,2.1115,3.56,2.28l0,0l0,0l0,0L2.15,5.54C1.475,5.702,0.9994,6.3059,1,7v3.5h1v1c0,0.5523,0.4477,1,1,1 s1-0.4477,1-1v-1h7v1c0,0.5523,0.4477,1,1,1s1-0.4477,1-1v-1h1V7z M4.3,3h6.4l1.05,2.5h-8.5L4.3,3z M3,9C2.4477,9,2,8.5523,2,8 s0.4477-1,1-1s1,0.4477,1,1S3.5523,9,3,9z M12,9c-0.5523,0-1-0.4477-1-1s0.4477-1,1-1s1,0.4477,1,1S12.5523,9,12,9z'%3E%3C/path%3E%3C/svg%3E");
}

.Map .styleControl .button-streets.active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' height='20' width='20'%3E%3Ctitle%3Ecar-15.svg%3C/title%3E%3Crect fill='none' x='0' y='0'  width='20' height='20'%3E%3C/rect%3E%3Cpath fill='%23333' transform='translate(0 0)' d='M14,7c-0.004-0.6904-0.4787-1.2889-1.15-1.45l-1.39-3.24l0,0l0,0l0,0C11.3833,2.1233,11.2019,2.001,11,2H4 C3.8124,2.0034,3.6425,2.1115,3.56,2.28l0,0l0,0l0,0L2.15,5.54C1.475,5.702,0.9994,6.3059,1,7v3.5h1v1c0,0.5523,0.4477,1,1,1 s1-0.4477,1-1v-1h7v1c0,0.5523,0.4477,1,1,1s1-0.4477,1-1v-1h1V7z M4.3,3h6.4l1.05,2.5h-8.5L4.3,3z M3,9C2.4477,9,2,8.5523,2,8 s0.4477-1,1-1s1,0.4477,1,1S3.5523,9,3,9z M12,9c-0.5523,0-1-0.4477-1-1s0.4477-1,1-1s1,0.4477,1,1S12.5523,9,12,9z'%3E%3C/path%3E%3C/svg%3E");
}

.Map .styleControl .button-hybrid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' height='20' width='20'%3E%3Ctitle%3Epark-15.svg%3C/title%3E%3Crect fill='none' x='0' y='0'  width='20' height='20'%3E%3C/rect%3E%3Cpath fill='%23aaa' transform='translate(0 0)' d='M14,5.75c0.0113-0.6863-0.3798-1.3159-1-1.61C12.9475,3.4906,12.4014,2.9926,11.75,3 c-0.0988,0.0079-0.1962,0.0281-0.29,0.06c-0.0607-0.66-0.6449-1.1458-1.3048-1.0851C9.8965,1.9987,9.6526,2.1058,9.46,2.28l0,0 c0-0.6904-0.5596-1.25-1.25-1.25S6.96,1.5896,6.96,2.28C6.96,2.28,7,2.3,7,2.33C6.4886,1.8913,5.7184,1.9503,5.2797,2.4618 C5.1316,2.6345,5.0347,2.8451,5,3.07C4.8417,3.0195,4.6761,2.9959,4.51,3C3.6816,2.9931,3.0044,3.659,2.9975,4.4874 C2.9958,4.6872,3.0341,4.8852,3.11,5.07C2.3175,5.2915,1.8546,6.1136,2.0761,6.9061C2.2163,7.4078,2.6083,7.7998,3.11,7.94 c0.2533,0.7829,1.0934,1.2123,1.8763,0.959C5.5216,8.7258,5.9137,8.2659,6,7.71C6.183,7.8691,6.4093,7.9701,6.65,8v5L5,14h5l-1.6-1 v-2c0.7381-0.8915,1.6915-1.5799,2.77-2c0.8012,0.1879,1.603-0.3092,1.7909-1.1103C12.9893,7.7686,13.0025,7.6444,13,7.52 c0.0029-0.0533,0.0029-0.1067,0-0.16C13.6202,7.0659,14.0113,6.4363,14,5.75z M8.4,10.26V6.82C8.6703,7.3007,9.1785,7.5987,9.73,7.6 h0.28c0.0156,0.4391,0.2242,0.849,0.57,1.12C9.7643,9.094,9.0251,9.6162,8.4,10.26z'%3E%3C/path%3E%3C/svg%3E");
}

.Map .styleControl .button-hybrid.active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' height='20' width='20'%3E%3Ctitle%3Epark-15.svg%3C/title%3E%3Crect fill='none' x='0' y='0'  width='20' height='20'%3E%3C/rect%3E%3Cpath fill='%23333' transform='translate(0 0)' d='M14,5.75c0.0113-0.6863-0.3798-1.3159-1-1.61C12.9475,3.4906,12.4014,2.9926,11.75,3 c-0.0988,0.0079-0.1962,0.0281-0.29,0.06c-0.0607-0.66-0.6449-1.1458-1.3048-1.0851C9.8965,1.9987,9.6526,2.1058,9.46,2.28l0,0 c0-0.6904-0.5596-1.25-1.25-1.25S6.96,1.5896,6.96,2.28C6.96,2.28,7,2.3,7,2.33C6.4886,1.8913,5.7184,1.9503,5.2797,2.4618 C5.1316,2.6345,5.0347,2.8451,5,3.07C4.8417,3.0195,4.6761,2.9959,4.51,3C3.6816,2.9931,3.0044,3.659,2.9975,4.4874 C2.9958,4.6872,3.0341,4.8852,3.11,5.07C2.3175,5.2915,1.8546,6.1136,2.0761,6.9061C2.2163,7.4078,2.6083,7.7998,3.11,7.94 c0.2533,0.7829,1.0934,1.2123,1.8763,0.959C5.5216,8.7258,5.9137,8.2659,6,7.71C6.183,7.8691,6.4093,7.9701,6.65,8v5L5,14h5l-1.6-1 v-2c0.7381-0.8915,1.6915-1.5799,2.77-2c0.8012,0.1879,1.603-0.3092,1.7909-1.1103C12.9893,7.7686,13.0025,7.6444,13,7.52 c0.0029-0.0533,0.0029-0.1067,0-0.16C13.6202,7.0659,14.0113,6.4363,14,5.75z M8.4,10.26V6.82C8.6703,7.3007,9.1785,7.5987,9.73,7.6 h0.28c0.0156,0.4391,0.2242,0.849,0.57,1.12C9.7643,9.094,9.0251,9.6162,8.4,10.26z'%3E%3C/path%3E%3C/svg%3E");
}
