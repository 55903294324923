.menu {
  z-index: 2;
  position: absolute;
  top: 37px;
  right: -10px;
  margin: 0 0 40px;
  padding: 7px 0;
  background: #fff;
  border-radius: 7px;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 6px 12px 0 rgba(0,0,0,.12);
  overflow: hidden;
  color: #000;
  font-size: 14px;
  font-weight: initial;
  text-align: left;
}

.menu:empty:before {
  content: "No options available";
  border-bottom: none !important;
}

.button .menu {
  top: 25px;
}

/* Menu Buttons */

.menu:empty:before,
.menu .button {
  display: block;
  white-space: nowrap;
  cursor: pointer;
  padding: 7px 24px;
  border-bottom: 1px solid #f2f2f2;
  transition: background .25s,
    color .25s;
  user-select: none;
}

.menu .button:hover {
  background: #f5f5f5;
}

.menu .button:last-child {
  border: none;
}

.menu .button .icon {
  height: 20px;
  fill: inherit;
  vertical-align: top;
  padding-right: 5px;
}

/* Menu Styles */

.menu .warning {
  color: #ff3b30;
  fill: #ff3b30;
}

.menu .warning:hover {
  background: #ff3b30;
  color: #fff;
  fill: #fff;
}


.menu:empty:before,
.menu .disabled,
.menu .disabled:hover {
  color: #bbb;
  background: none;
  cursor: default;
}

/* Menu Indicator */

.hasMenu {
  cursor: pointer;
}

.hasMenu.showMenu {
  cursor: default;
}

.hasMenu.showMenu:before {
  color: #000;
}

/*** Mobile Style ***/

@media only screen and (max-width: 768px), (pointer: coarse) {
  .menu:empty:before,
  .menu .button {
    font-size: 16px;  /* Same as mobile input font size */
    padding: 10px 24px;
  }
}
