#modal_container ~ .page_buttons.top.right {
  display: none;
}

.modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: table;
  min-width: 400px;
  border-radius: 10px;
  padding: 15px;
  background: #fff;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 6px 12px 0 rgba(0,0,0,.12);
  font-size: 14px;
  text-align: left;
  -webkit-background-clip: padding-box;  /* Safari border radius overflow bug fix */
}

.modal_content {
  max-height: calc(100vh - 30px - 44px*2 - 40px);  /* Screen height - padding - header - top/bottom space */
  overflow: auto;
}

/*** Title ***/

.modal h1 {
  font-size: 18px;
  text-align: center;
  padding: 0;
  margin: 0 0 20px;
  position: relative;
  font-weight: 600;
  line-height: 1;
}

.modal h1 .close {
  position: absolute;
  bottom: 13px;
  left: 15px;
  font-size: inherit;
  color: rgb(0, 122, 255);
  cursor: pointer;
  display: none;
  font-weight: normal;
}

/*** Message ***/

.modal .message {
  margin: 10px auto;
  padding: 0 10px 0;
  width: 380px;
  text-align: center;
}

/*** Barcode ***/

.modal .barcode_container {
  margin: 10px 0;
  text-align: center;
}

.modal .barcode {
  position: relative;
  font-size: 0;
  border: 1px solid;
  border-radius: 10px;
  display: inline-block;
  padding: 5px 10px 2px;
  background: #fefefe;
  cursor: pointer;
  fill: black;
  width: 100px;
}

.modal .barcode .scanStatus {
  position: relative;
  margin: 2px auto;
  font-size: 12px;
  font-family: Courier, monospace;
}

.modal .barcode.scanning .icon::before {
  content: "";
  width: 110px;
  border-top: 2px solid red;
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  right: 0;
  margin: auto;
}

.modal .barcode.scanning .icon[name=qr-code]::before {
  width: 37px;
}

.modal .barcode.error .scanStatus {
  color: red;
}

.modal .barcode svg {
  fill: inherit;
  margin: auto;
  width: 100%;
  height: 27px;
}

.modal .barcode svg rect {
  height: 100%;
}

/*** Map ***/

.modal .Map {
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px;
}

/*** Inputs ***/

.modal .hideInput {
  font-size: 10px;
  width: 0;
  height: 0;
  overflow: hidden;
  caret-color: #ffffff00;
}

.modal .inputs {
  width: 100%;
  margin: 10px 0;
  padding: 0 15px;
  line-height: 1;
}

.modal .inputs .hidden_row {
  visibility: collapse;
}

.modal .inputs th {
  font-weight: normal;
  text-align: right;
  white-space: nowrap;
  padding-right: 10px;
}

.modal .inputs td {
  width: 100%;
  padding: 5px 0;
}

.modal .inputs.hidden {
  display: none !important;
}

.modal .label {
  padding: 10px 0;
  text-align: right;
  height: 14px;
}

.modal .label.required::after {
  content: "*";
  color: red;
  padding-left: 3px;
}

.modal input,
.modal .checkbox label,
.modal select {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid;
  border-color: #ccc;
  border-radius: 5px;
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  padding: 0 10px;
  line-height: normal;
  font-size: inherit;
  background: #fff;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  opacity: 1;
}

.modal select::-ms-expand {
  display: none;
}

.modal input,
.modal .checkbox label,
.modal select,
.modal .select {
  position: relative;
  height: 34px;
}

.modal .checkbox {
  position: relative;
}

.modal input[type=checkbox] {
  /* visibility: hidden; */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
  margin: 0;
}

.modal input::placeholder {
  color: inherit;
  opacity: .25;
}

/* Checkbox Inputs */

.modal .checkbox label {
  display: block;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 5px 0;
}

.modal .checkbox label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}

.modal .checkbox input[type=checkbox]:checked ~ label:before {
  content: "\2714";
}

.modal .select::after {
  content: "\25BE";
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

/* Disabled Inputs */

.modal input:disabled,
.modal select:disabled,
.modal .select.disabled::after,
.modal .checkbox input[type=checkbox]:disabled ~ label:before {
  color: #999;
  -webkit-text-fill-color: #999;  /* Override iOS styles */
  background: #fafafa;
  cursor: default;
}

/*** Buttons ***/

.modal .buttons {
  text-align: center;
  margin: 20px 0 10px;
}

.modal .buttons input {
  background-color: #fefefe;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
  width: auto;
  height: auto;
  line-height: normal;
}

/*** Mobile Style ***/

@media only screen and (max-width: 576px){
  .modal {
    top: 0;
    left: 0;
    transform: initial;
    display: block;
    min-width: initial;
    height: 100%;
    border-radius: 0;
    border-width: 0;
    padding: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal_content {
    max-height: initial;
  }

  .modal h1 {
    padding: 13px;
    /* padding-top: SEE INLINE STYLE */
    margin-top: 4px;
    height: 18px;
    border-bottom: 1px solid;
    border-color: inherit;
  }

  .modal h1 .close {
    display: initial;
  }

  .modal .barcode_container {
    margin: 15px 0;
  }

  .modal .message {
    width: auto;
    padding: 0 15px;
    font-size: 16px;
    margin: 15px 0;
  }

  .modal .Map {
    border-radius: 0;
    margin: -20px 0 15px 0;
  }

  .modal .inputs {
    margin-top: -10px;
    padding: 0 30px;
  }

  .modal .inputs th {
    display: block;
    text-align: left;
    padding: 0;
  }

  .modal .inputs td {
    display: block;
    padding: 0;
  }

  .modal .label {
    font-size: 16px;
    text-align: left;
    display: block;
  }

  .modal .checkbox-row th:first-of-type {
    display: none;
  }

  .modal .checkbox-row th {
    display: inline-block;
  }

  .modal .checkbox-row td {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .modal input,
  .modal .checkbox label,
  .modal select,
  .modal .select {
    margin-bottom: 10px;
    font-size: 16px;  /* Prevent auto-zoom on inputs on iOS */
    height: 36px;
    line-height: normal;
  }

  .modal input[type=checkbox],
  .modal .checkbox label {
    width: 30px;
    height: 30px;
  }

  .modal .checkbox label {
    margin: 10px 10px 10px 0;
  }

  .modal .checkbox label:before {
    line-height: 30px;
    font-size: 20px;
  }

  .modal .buttons {
    margin-bottom: 40px;
  }
}
