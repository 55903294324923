/*** Items ***/

.AppPage .card {
  font-size: 14px;
}

/*** Buckets ***/

.Bucket h1.required::after {
  content: "*";
  color: red;
  padding-left: 3px;
  cursor: help;
}

.AppPage .Bucket .emptyBucket {
  padding: 9px;
  text-align: center;
  color: #ccc;
}

.AppPage .Bucket .more {
  text-align: center;
  position: relative;
  margin-top: -8px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}

.AppPage .Bucket .more::after {
  content: "";
  position: absolute;
  top: -29px;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), white);
  height: 29px;
}

.AppPage .Bucket.viewingMore .more::after {
  display: none;
}

.AppPage .Bucket .moreButton {
  border: 0 !important;
  border-bottom: 1px solid !important;
  border-color: #fff !important;
}

.AppPage .Bucket .more:hover .moreButton {
  border-color: initial !important;
}

/*** Archive Toggle ***/

.AppPage .toggle {
  display: block;
  width: 175px;
  border-radius: 10px;
  border: 1px solid;
  margin: -5px auto 25px;
  text-align: center;
  overflow: hidden;
  font-size: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  background: #999;
  color: #999;
}

.AppPage .toggle * {
  display: inline-block;
  padding: 4px 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  width: 50%;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  background: #fff;
}

.AppPage .toggle .active {
  background: inherit;
  color: #fff;
  cursor: default;
}

/* Archive Searchbar */

.AppPage .searchbar {
  margin: 0 auto 20px;
  text-align: center;
}

.AppPage .searchbar input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid;
  border-color: #ccc;
  border-radius: 5px;
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  padding: 0 10px;
  line-height: normal;
  font-size: inherit;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  opacity: 1;
  height: 34px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.AppPage .searchbar input::placeholder {
  color: inherit;
  opacity: .25;
}

/*** Item Buttons & Loading ***/

.AppPage .card .loading {
  position: absolute;
  background: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: inherit;
  cursor: default;
  text-align: center;
  background: #fff;
}

.AppPage .card .loading .icon[name=spinner] {
  width: 50px;
  fill: #777;
}

.AppPage .card .loading_wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
}

.AppPage .archiveButtons {
  margin: 5px auto 20px;
  text-align: center;
}

.AppPage .Bucket .moreButton,
.AppPage .download,
.AppPage .loadMore {
  display: inline-block;
  position: relative;
  width: 125px;
  padding: 4px 0;
  border-radius: 10px;
  border: 1px solid;
  margin: 0 10px;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  background: #fff;
  color: #999;
  cursor: pointer;
}

.AppPage .Bucket .moreButton,
.AppPage .download {
  padding: 0;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
  width: auto;
}

.AppPage .archiveButtons br:first-child {
  display: initial;
}

.AppPage .loadMore ~ .download {
  margin-top: 25px;
}

.AppPage .loadMore .icon {
  height: 40px;
  fill : #999;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: none;
}

.AppPage .loadMore.loading {
  color: red;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  background: none;
  color: rgba(0, 0, 0, 0);
}

.AppPage .loadMore.loading .icon {
  display: initial;
}

/*** Mobile Style ***/

@media only screen and (max-width: 576px){
  /* Hide button title in topright if button has icon */
  .page_buttons.top .icon ~ span {
    display: none;
  }

  .page_buttons.bottom {
    bottom: 15px;
  }

  .page_buttons.bottom .button {
    margin: 10px 0;
  }
}
