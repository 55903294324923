.HomePage .widgets {
  margin: auto;
  max-width: 825px;
  text-align: center;
  /* min-height: SEE INLINE STYLE */
}

.widget {
  display: inline-block;
  margin: 0 20px 40px;
  width: 125px;
  vertical-align: top;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

@media only screen and (min-width: 846px){
  .widget:nth-child(5n + 1):last-child {
    margin-right: calc(20px + 165px * 4);
  }
  .widget:nth-child(5n + 2):last-child {
    margin-right: calc(20px + 165px * 3);
  }
  .widget:nth-child(5n + 3):last-child {
    margin-right: calc(20px + 165px * 2);
  }
  .widget:nth-child(5n + 4):last-child {
    margin-right: calc(20px + 165px);
  }
  .widget:nth-child(1):last-child { margin-right: 20px; }
  .widget:nth-child(2):last-child { margin-right: 20px; }
  .widget:nth-child(3):last-child { margin-right: 20px; }
  .widget:nth-child(4):last-child { margin-right: 20px; }
}

@media only screen and (max-width: 845px) and (min-width: 681px){
  .widget:nth-child(4n + 1):last-child {
    margin-right: calc(20px + 165px * 3);
  }
  .widget:nth-child(4n + 2):last-child {
    margin-right: calc(20px + 165px * 2);
  }
  .widget:nth-child(4n + 3):last-child {
    margin-right: calc(20px + 165px);
  }
  .widget:nth-child(1):last-child { margin-right: 20px; }
  .widget:nth-child(2):last-child { margin-right: 20px; }
  .widget:nth-child(3):last-child { margin-right: 20px; }
}

@media only screen and (max-width: 680px) and (min-width: 516px){
  .widget:nth-child(3n + 1):last-child {
    margin-right: calc(20px + 165px * 2);
  }
  .widget:nth-child(3n + 2):last-child {
    margin-right: calc(20px + 165px);
  }
  .widget:nth-child(1):last-child { margin-right: 20px; }
  .widget:nth-child(2):last-child { margin-right: 20px; }
}

@media only screen and (max-width: 515px) and (min-width: 371px){
  .widget:nth-child(2n + 1):last-child {
    margin-right: calc(20px + 165px);
  }
  .widget:nth-child(1):last-child { margin-right: 20px; }
}

@media only screen and (max-width: 370px) and (min-width: 310px){
  .widget:nth-child(2n + 1):last-child {
    margin-right: calc(10px + 145px);
  }
  .widget:nth-child(1):last-child { margin-right: 10px; }
  
  .HomePage .cards {
    padding-left: 10px;
    padding-right: 10px;
  }

  .widget {
    margin: 10px;
  }
}

.widget .widget_icon {
  height: 123px;  /* 125px - border*2 */
  width: calc(100% - 2px);  /* 100% - border*2 */
  border-radius: 15px;
  background: #bbb;
  position: relative;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
}

.widget .widget_icon .icon {
  width: 75%;
  height: 75%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  fill: #fff;
}

.widget h1 {
  font-size: 16px;
  padding: 10px 0 0;
  margin: 0;
  text-align: center;
  font-weight: normal;
  width: 100%;
}
