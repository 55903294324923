/*** Header ***/

.header {
  padding: 9px;
  /* padding-top: SEE INLINE STYLE */
  height: 26px;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: #3F9AF7;
  z-index: 50;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.header .title {
  display: inline-block;
  margin: auto;
  font-size: 0;
  font-weight: 600;
  height: 26px;
  vertical-align: middle;
}

.header .title .icon {
  fill: #fff;
  height: 22px;
  width: 22px;
  vertical-align: middle;
  padding-right: 5px;
}

.header .title span {
  vertical-align: middle;
  font-size: 18px;
}

/*** Spacer ***/
.spacer {
  display: block;
  padding: 0;
  margin: 0;
}

/*** Footer ***/

.footer {
  text-align: center;
  font-size: 14px;
  color: #999;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.footer span {
  display: block;
  padding: 5px 0 5px;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer.disabled a {
  text-decoration: none !important;
}

.footer a:hover {
  text-decoration: underline;
}
