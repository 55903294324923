.BillingPage .description {
  margin: 0 auto 25px;
  padding: 15px;
  max-width: 600px;
  background: rgb(230, 255, 230);
  color: rgb(39, 174, 96);
  border: 1px solid;
  border-radius: 10px;
}

.BillingPage .description.no_billing {
  background: #fafafa;
  color: #777;
}

.BillingPage .showcc {
  width: 125px;
  padding: 4px 10px;
  border-radius: 10px;
  border: 1px solid;
  margin: 0 auto 20px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  background: #fff;
  color: #999;
  cursor: pointer;
  text-align: center;
}

.BillingPage form {
  margin: 20px auto 30px;
  max-width: 400px;
  border-radius: 10px;
  padding: 15px 30px;
  background: #fafafa;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  font-size: 14px;
  text-align: left;
  position: relative;
}

.BillingPage .default_indicator {
  position: absolute;
  top: 10px;
  right: 15px;
  display: inline-block;
  padding: 3px 6px;
  border: 1px solid;
  color: rgba(52, 152, 219, 1);
  background: rgba(52, 152, 219, .2);
  border-radius: 3px;
}

.BillingPage .filled {
  padding-bottom: 25px;
}

.BillingPage form h1 {
  font-size: 18px;
  font-weight: inherit;
  text-align: center;
  padding: 9px 0 0;
  margin: 0 0 20px;
  position: relative;
  font-weight: 500;
  line-height: 1;
}

.BillingPage form p {
  margin: 15px 0;
  padding: 5px 10px;
  text-align: center;
}

.BillingPage form .input,
.BillingPage form input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid;
  border-color: #ccc;
  border-radius: 5px;
  font-family: inherit;
  padding: 10px;
  line-height: normal;
  font-size: inherit;
  background: #fff;
  position: relative;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
}

.BillingPage form .input {
  margin: 15px 0;
  height: 39px;
}

.BillingPage .filled .input {
  font-family: "Source Code Pro", "Consolas", "Menlo", "monospace";
  text-transform: uppercase;
  background: #000;
  color: #fff;
}

.BillingPage .filled #brand::after {
  content: ": ";
}

.BillingPage .filled #last4::before {
  content: "**** **** **** ";
}

.BillingPage .filled.bank #last4 {
  float: right;
}

.BillingPage .filled.bank #last4::before {
  content: "********";
}

.BillingPage .filled .amex #last4::before {
  content: "**** ****** *";
}

.BillingPage .filled .exp {
  float: right;
}

.BillingPage .filled #exp_month::after {
  content: "/";
  padding: 0 4px;
}

@media only screen and (max-width: 500px){
  .BillingPage .filled .input {
    overflow-x: auto;
    white-space: nowrap;
    text-align: center;
  }

  .BillingPage .filled #last4::before {
    content: "" !important;
  }

  .BillingPage .filled .exp,
  .BillingPage .filled.bank #last4 {
    float: none;
    padding-left: 20px;
  }
}

.BillingPage form .error {
  color: red;
  text-align: center;
}

.BillingPage form.bank .error {
  margin-top: -15px;
}

.BillingPage form .buttons {
  text-align: center;
  margin: 20px 0 10px;
}

.BillingPage form .buttons input {
  background-color: #fefefe;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
  width: auto;
  height: auto;
  line-height: normal;
}

.BillingPage form .icon {
  width: 50px;
  margin: -4px auto;
  fill: #777;
  display: block;
}
